<template>
    <div class="PosterList">
        <div class="flex">
            <a-button-group size="large">
                <a-button
                        :class="TableStatus ? '' : 'ant-btn-primary'"
                        @click="SearchData('')"
                >全部
                </a-button
                >
                <a-button
                        v-if="categoryList"
                        v-for="item in categoryList"
                        @click="SearchData(item.Id)"
                        :class="TableStatus == 1 ? 'ant-btn-primary' : ''"
                >{{ item.title }}
                </a-button
                >
                <!--<a-button @click="SearchData(2)" :class="TableStatus==2?'ant-btn-primary':''">合同</a-button>-->
                <!--<a-button @click="SearchData(3)" :class="TableStatus==3?'ant-btn-primary':''">证书</a-button>-->
                <!--<a-button @click="SearchData(4)" :class="TableStatus==4?'ant-btn-primary':''">其它</a-button>-->
            </a-button-group>
            <div>
                <a-input-search
                        placeholder="搜索名称"
                        style="width: 230px;margin-right: 23px"
                        v-model="KeyWord"
                        @search="onSearch"
                />
                <a-button type="primary" @click="add" style="margin-right: 23px"
                >+&nbsp;新建
                </a-button
                >
            </div>
        </div>
        <!--内容start-->
        <div class="content">
            <a-card
                    v-if="data"
                    v-for="(item, index) in data"
                    hoverable
                    style="width: 240px;display: inline-block;margin-right: 31px"
            >
                <img
                        style="width: 238px;height: 297px"
                        alt="example"
                        :src="item.BgUrl + '?imageView2/1/w/238/h/297'"
                        slot="cover"
                />
                <a-card-meta>
                    <template slot="description">
                        <span style="color:rgba(45,62,93,1);">{{ item.Name }}</span>
                        <a-dropdown>
                            <a
                                    class="ant-dropdown-link"
                                    href="#"
                                    style="width: 50px;display: inline-block;float: right"
                            >
                                ...
                            </a>
                            <a-menu slot="overlay">
                                <a-menu-item>
                                    <a href="javascript:;" @click="SaveHoliday(item.Id, index)"
                                    ><i class="iconfont icon-bianji"></i>&nbsp;编辑</a
                                    >
                                </a-menu-item>
                                <a-menu-item>
                                    <a href="javascript:;" @click="CopyHoliday(item.Id)">
                                        <i class="iconfont icon-fuzhi"></i>&nbsp;复制</a
                                    >
                                </a-menu-item>
                                <a-menu-item>
                                    <a href="javascript:;" @click="PreviewHoliday(item.Id, index)"
                                    ><i class="iconfont icon-bianji"></i>&nbsp;预览</a
                                    >
                                </a-menu-item>
                                <a-menu-item>
                                    <a-popconfirm
                                            placement="bottom"
                                            cancelText="取消"
                                            okText="确定"
                                            @confirm="DelHoliday(item.Id)"
                                            @cancel="maxClassify = -1"
                                    >
                                        <template slot="title">
                                            <p>确定删除么？</p>
                                        </template>
                                        <i class="iconfont icon-shanchu-">&nbsp;删除</i>
                                    </a-popconfirm>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </template>
                </a-card-meta>
            </a-card>
            <a-pagination
                    v-if="count > Limit"
                    style="margin-top: 35px;text-align: right;"
                    @change="onChange"
                    :pageSize="Limit"
                    :total="count"
            />
        </div>
        <!--内容end-->

        <!--新建弹窗start-->
        <a-drawer
                :title="Id ? '编辑模板' : '创建模板'"
                width="520"
                :closable="false"
                @close="onClose"
                :visible="visible"
        >
            <div class="form-item">
                <label class="label-text">名称</label>
                <a-input v-model="Name" placeholder="请输入"/>
            </div>
            <div class="form-item">
                <label class="label-text">分类</label>
                <a-select
                        v-model="Category"
                        style="width: 100%;display: block;"
                        placeholder="请选择"
                >
                    <a-select-option
                            v-if="categoryList"
                            v-for="item in categoryList"
                            :value="item.Id"
                    >{{ item.title }}
                    </a-select-option
                    >
                </a-select>
            </div>
            <div class="form-item">
                <label class="label-text">模板底图</label>
                <a-button class="upload">
                    <a-icon type="upload"/>
                    上传图片
                    <input
                            id="upload"
                            accept=".jpg,.png,.gif,.jpeg"
                            type="file"
                            @change="_upload($event, 'upload')"
                    />
                </a-button>
                <img
                        v-if="BgUrl"
                        :src="BgUrl"
                        alt=""
                        style="width: 100px;display: block;margin-top: 20px"
                />
                <span class="upload-tips">建议图片尺寸1080*1920像素，大小不超过2M</span>
            </div>

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="submit" type="primary"
                >确认
                </a-button
                >
                <a-button @click="onClose">取消</a-button>
            </div>
        </a-drawer>
        <!--新建弹窗end-->
        <div style="display: none" v-viewer>
            <img id="images" :src="fileUrl" alt=""/>
        </div>
    </div>
</template>

<script>
    import 'viewerjs/dist/viewer.css'
    import Viewer from 'v-viewer'
    import Vue from 'vue'

    Vue.use(Viewer)
    export default {
        name: 'PosterList',
        data() {
            return {
                TableStatus: '',
                visible: false,
                Name: '',
                Category: '',
                BgUrl: '',
                Id: '',
                KeyWord: '',
                page: 0,
                Limit: 12,
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                data: '',
                count: '',
                DataImg: [],
                DataText: [
                    {
                        Xlong: '200',
                        Ylong: '200',
                        fontSize: '28',
                        rotate: '0',
                        color: '#ff0000',
                        text: '',
                        font: 'SourceHanSansCN-Bold',
                        value: '员工姓名',
                        rgb: {a: '1', r: '244', g: '67', b: '54'},
                        Field: 'Name'
                    }
                ],
                fileUrl: '',
                categoryList: ''
            }
        },
        created() {
            this.LoadData()
            this.DataText[0].text = this.userinfo.name
            this._categoryList()
        },

        methods: {
            _categoryList() {
                let self = this
                this.$axios.get(
                    1200,
                    {
                        label: 12345678
                    },
                    res => {
                        self.categoryList = res.data.data
                    }
                )
            },
            LoadData() {
                let self = this
                this.$axios.get(
                    1054,
                    {
                        uid: self.userinfo.uid,
                        Name: self.KeyWord,
                        Category: self.TableStatus,
                        Limit: self.Limit,
                        page: self.page
                    },
                    res => {
                        self.data = res.data.message.data
                        self.count = res.data.message.count
                    }
                )
            },
            //编辑
            SaveHoliday(id) {
                this.$router.push({path: '/Poster', query: {id: id}})
            },
            //复制
            CopyHoliday(id) {
                let self = this
                this.$axios.post(
                    1058,
                    {
                        Id: id,
                        uid: self.userinfo.uid,
                        UserName: self.userinfo.name
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            this.$message.success(res.data.message)
                            self.LoadData()
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            //预览
            PreviewHoliday(id, index) {
                let self = this
                var e = document.createEvent('MouseEvents') //这里的clickME可以换成你想触发行为的DOM结点
                e.initEvent('click', true, true) //这里的click可以换成你想触发的行为
                this.$axios.post(
                    1052,
                    {
                        dataText: self.data[index].DataText,
                        dataImg: self.data[index].DataImg
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.fileUrl = res.data.message
                            setTimeout(function () {
                                document.getElementById('images').dispatchEvent(e)
                            }, 500)
                        } else {
                            self.$message.error(res.data.message)
                        }
                    }
                )
            },
            //删除
            DelHoliday(id) {
                let self = this
                this.$axios.post(
                    1056,
                    {
                        Id: id,
                        uid: self.userinfo.uid
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.$message.success(res.data.message)
                            self.LoadData()
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            onChange(value) {
                this.page = value
                this.LoadData()
            },

            SearchData(value) {
                this.TableStatus = value
                this.LoadData()
            },
            submit() {
                if (this.Name == '') {
                    this.$message.error('请输入名称')
                    return false
                }
                if (this.Category == '') {
                    this.$message.error('请选择分类')
                    return false
                }
                if (this.BgUrl == '') {
                    this.$message.error('请上传模板地图')
                    return false
                }

                let self = this
                this.$axios.post(
                    1055,
                    {
                        Name: self.Name,
                        Category: self.Category,
                        BgUrl: self.BgUrl,
                        Id: self.Id,
                        uid: self.userinfo.uid,
                        UserName: self.userinfo.name,
                        DataImg: JSON.stringify(self.DataImg),
                        DataText: JSON.stringify(self.DataText)
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.$message.success(res.data.message)
                            self.LoadData()
                            self.visible = false
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            onSearch() {
                this.LoadData()
            },
            add() {
                this.visible = true
            },
            onClose() {
                this.Id = ''
                this.Name = ''
                this.Category = ''
                this.BgUrl = ''
                this.visible = false
            },

            _upload(e, name) {
                let self = this
                let files = document.getElementById(name).files
                let type = files[0].type
                var img = new Image()
                let arr = {}
                let width = ''
                let height = ''
                if (type.indexOf('image/') != 0) {
                    this.$message.error('只允许上传jpg、gif、png、jpeg格式的图片')
                    return false
                }
                this.__uploadFile__(files).then(res => {
                    if (res) {
                        self.BgUrl = res[0].FileUrl
                        arr.url = res[0].FileUrl
                        arr.Xlong = 0
                        arr.Ylong = 0
                        img.src = res[0].FileUrl
                        img.onload = function () {
                            width = img.width
                            height = img.height
                            arr.width = width
                            arr.height = height
                        }
                        self.DataImg.push(arr)
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    * {
        font-family: Microsoft YaHei;
    }

    .content {
        margin-top: 25px;
    }

    .form-item {
        margin-bottom: 24px;
        .label-text {
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
            margin-bottom: 8px;
            display: block;
        }
    }

    .PosterList {
        background: #ffffff;
        padding: 25px;
        min-height: 800px;
    }

    .upload {
        display: block;
        position: relative;
        cursor: pointer;
        #upload {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .upload-tips {
        color: rgba(153, 153, 153, 1);
        display: block;
        margin-top: 8px;
        font-size: 12px;
    }
</style>
